import React, { useEffect, useState } from 'react';
import { Select, MenuItem, Box, InputLabel, Checkbox, Button } from '@mui/material';

function PeriodDropdown({ employeeData, setSelectedPeriods, employeeSelected }) {
	const [selectedItems, setSelectedItems] = useState([]);

	const handleCheckChange = (event, periodId) => {
		// Stop event propagation
		event.stopPropagation();

		if (event.target.checked) {
			setSelectedItems((prevState) => {
				const newState = [...prevState, periodId];
				//console.log('Selected Items: ' + newState);
				return newState;
			});
		} else {
			setSelectedItems((prevState) => {
				const newState = prevState.filter((item) => item !== periodId);
				//console.log('Selected Items: ' + newState);
				return newState;
			});
		}
	};

	const handlePeriodChange = () => {
		setSelectedPeriods(selectedItems);
		//console.log('Selected Periods: ', selectedItems);
	};

	useEffect(() => {
		if (!employeeSelected || !employeeData || employeeData.periods.length === 0) {
			setSelectedPeriods([{}]);
		}
	}, [employeeSelected, employeeData, setSelectedPeriods]);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				width: '510px',
			}}>
			<InputLabel htmlFor="period-dropdown" shrink sx={{ marginTop: 1, width: 350 }}>
				<b style={{ fontSize: 25 }}>Select an Evaluation:</b>
			</InputLabel>

			<Select
				labelId="period-dropdown"
				onChange={handlePeriodChange}
				defaultValue=""
				value={[]}
				displayEmpty
				sx={{ marginLeft: -9, flexGrow: 1 }}>
				{employeeSelected && employeeData && employeeData.periods.length > 0 && (
					<MenuItem value="" disabled>
						<em style={{ color: '#d3d3d3' }}>Select an Evaluation</em>
					</MenuItem>
				)}
				{employeeData === null || employeeData.length === 0 ? (
					<MenuItem value="" disabled>
						{!employeeSelected ? (
							<em style={{ color: '#d3d3d3' }}>Select Employee First</em>
						) : employeeData === null ? (
							<em style={{ color: '#d3d3d3' }}>Loading...</em>
						) : (
							<em style={{ color: '#d3d3d3' }}>No Evaluations to show</em>
						)}
					</MenuItem>
				) : (
					employeeData.periods.map((period) => (
						<MenuItem key={period._id} value={JSON.stringify(period._id)}>
							<Checkbox
								onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
								onChange={(event) => handleCheckChange(event, period._id)}
								checked={selectedItems.includes(period._id)}
							/>
							{period.startDate}
						</MenuItem>
					))
				)}
				<MenuItem>
					<Button
						onClick={(event) => {
							handlePeriodChange();
						}}>
						View
					</Button>
				</MenuItem>
			</Select>
		</Box>
	);
}

export default PeriodDropdown;
